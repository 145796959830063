<template>
	<div>
		<ktv-table
			mode="remote"
			:is-loading="loadingGrid"
			:columns="columns"
			:rows="rows"
			:total-rows="getTotalRecordsCvc"
			:rows-per-page="serverParams.rowPerpage"
			:search-custom-enabled="false"
			:filter="false"
			:search-enabled="false"
			@on-open-filter="openFilter()"
			@on-page-change="onPageChange"
			@on-sort-change="onSortChange"
			@on-per-page-change="onPerPageChange"
		>
			<template #actionright>
				<b-row class="mt-2">
					<v-select
						v-model="filter.provinceId"
						:options="options.provinceOptions"
						:placeholder="$t('Filter Province', 'Filter Province')"
						class="col-6"
						@input="setDistrict"
					/>
					<v-select
						v-model="filter.districtId"
						:options="options.districtOptions"
						:placeholder="loading ? `${$t('Loading', 'Loading')}...` : $t('Filter District', 'Filter District')"
						class="col-6"
						@input="pilihandistrict"
					/>
				</b-row>
			</template>
			<template #actionleft>
				<ktv-button icon="add" :text="$t('Add New', 'Add New')" color="primary" @click="onPopup()" />
			</template>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<span><i class="nav-icon i-Arrow-Down-in-Circle" /></span>
						</template>
						<b-dropdown-item href="#" @click="deletecvc(props.row.entityId)">
							<i class="i-Close-Window text-14 text-success mr-2" />
							{{ $t("Delete", "Delete") }}
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field === 'isCvcHead'">{{ props.row.isCvcHead === 1 ? $t("Yes", "Yes") : $t("No", "No") }}</span>
			</template>
		</ktv-table>

		<ktv-modal v-model="modalsFilter" size="lg" :title="$t('CVC Head', 'CVC Head')" @close="modalsFilter = false">
			<template #content>
				<tables-grid-pilih @close-dialog="modalsFilter = false" />
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	const column = [
		{
			label: "Action",
			field: "button",
			html: true,
			tdClass: "text-center",
			thClass: "text-center",
			sortable: false,
		},
		{
			label: "CVC Name",
			field: "name",
			thClass: "text-left",
		},
		{
			label: "Address",
			field: "address",
			thClass: "text-left",
		},
		{
			label: "Bank Name",
			field: "BankName",
			thClass: "text-left",
		},
		{
			label: "Account Holder",
			field: "accountHolderName",
			thClass: "text-left",
		},
		{
			label: "Account Number",
			field: "bankAccount",
			thClass: "text-left",
		},
		{
			label: "Assign CVC Head",
			field: "isCvcHead",
			thClass: "text-left",
		},
	]

	import { KtvTable, KtvButton, KtvModal } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"
	import { map } from "underscore"
	import TablesGridPilih from "../tables/TablesGridPilih"
	export default {
		components: {
			KtvTable,
			KtvButton,
			KtvModal,
			TablesGridPilih,
		},
		data() {
			return {
				modalsFilter: false,
				loadingGrid: false,
				loading: false,
				filter: {
					provinceId: null,
					districtId: null,
				},
				options: {
					provinceOptions: [],
					districtOptions: [],
				},
				/* KtvTable property */
				rows: [],
				columns: column,
				serverParams: {
					search: null,
					order: null,
					currentPage: 1,
					rowPerpage: 10,
					DistrictID: null,
				},
				sortKey: "",
				/* End KtvTable property */
			}
		},
		computed: {
			...mapGetters({
				getTotalRecordsCvc: "AGRIINPUTREQUEST/getTotalRecordsCvc",
			}),
			...mapState("OTHERS", ["provinceList", "districtList"]),
		},
		watch: {
			provinceList: {
				deep: true,
				handler() {
					this.getProvinceOptions()
				},
				immediate: true,
			},
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
		},
		mounted() {
			this.getProvinceList()
		},
		methods: {
			...mapActions({
				getProvinceList: "OTHERS/getProvinceList",
				getDistrictList: "OTHERS/getDistrictList",
				ActionCVCTablesGrid: "AGRIINPUTREQUEST/ActionCVCTablesGrid",
				ActionDeleteCVC: "AGRIINPUTREQUEST/ActionDeleteCVC",
			}),
			onPopup() {
				this.modalsFilter = true
			},
			getProvinceOptions() {
				this.options.provinceOptions = map(this.provinceList, (province) => {
					return {
						label: province.province,
						id: parseInt(province.entityProvinceID, 10),
					}
				})
			},
			getDistrictOptions() {
				this.loading = false
				this.options.districtOptions = map(this.districtList, (district) => {
					return {
						label: district.district,
						id: parseInt(district.entityDistrictID, 10),
					}
				})
			},
			setDistrict(value) {
				this.loading = true
				if (value !== null) {
					this.getDistrictList(value.id)
				} else {
					this.loading = false
					this.options.districtOptions = []
				}
			},
			pilihandistrict() {
				this.loadItems()
			},
			/**
			 * KtvTables Methods
			 */
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			// Untuk Paging Next - Previous hit
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			// Untuk melakukan Jumlah dropdown Rowsperpages
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			// Untuk melakukan Sorting data
			onSortChange(params) {
				this.sortKey = params[0].field
				this.columns[params[0].field] = !this.columns[params[0].field]
				let oParams = {
					namaField: params[0].field,
					orderBy: this.columns[params[0].field] === false ? "asc" : "desc",
				}
				const NewParams = new URLSearchParams(oParams).toString()
				this.serverParams.order = NewParams
				this.loadItems()
			},
			// Untuk Load Data
			loadItems() {
				this.loadingGrid = true
				let id = ""
				if (this.filter.districtId !== null) {
					id = this.filter.districtId.id
				}
				this.serverParams.districtId = id
				this.ActionCVCTablesGrid(this.serverParams).then((resp) => {
					this.loadingGrid = false
					if (resp) this.rows = resp.data
				})
			},
			async deletecvc(entityId) {
				this.$swal({
					type: "warning",
					title: this.$t("Confirmation", "Confirmation"),
					text: this.$t("Are you sure you want to delete the data?", "Are you sure you want to delete the data?"),
					showConfirmButton: true,
					showCancelButton: true,
					confirmButtonText: this.$t("Delete", "Delete"),
					cancelButtonText: this.$t("Cancel", "Cancel"),
				}).then(async (isConfirm) => {
					if (isConfirm.value === true) {
						this.$swal.fire({
							title: `${this.$t("Loading", "Loading")}...`,
							html: this.$t("Data will be deleted", "Data will be deleted"),
							allowOutsideClick: false,
							onBeforeOpen: () => {
								this.$swal.showLoading()
							},
						})
						await this.ActionDeleteCVC(entityId)
						this.loadItems()
					}
				})
			},
		},
	}
</script>
<style>
	.child-div {
		width: 100%;
	}
</style>

<template>
	<div class="main-content">
		<!--BreadCrumbs-->
		<ktv-breadcrumb :folder="$t('CVC', 'CVC')" :page="$t('CVC', 'CVC')" :title="$t('CVC List', 'CVC List')" />
		<!--Tables Grid-->
		<TablesGrid />
	</div>
</template>
<script>
	import { KtvBreadcrumb } from "@/components"
	import TablesGrid from "./tables/TablesGrid"

	export default {
		metaInfo: {
			title: "CVC",
		},
		components: {
			KtvBreadcrumb,
			TablesGrid,
		},
	}
</script>

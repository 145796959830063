<template>
	<div>
		<b-row>
			<b-col cols="4">
				<b-form-group>
					<ktv-select
						v-model="filter.provinceId"
						:options="options.provinceOptions"
						:label="$t('Filter Province', 'Filter Province')"
						@input="setDistrict"
					/>
				</b-form-group>
			</b-col>
			<b-col cols="4">
				<b-form-group>
					<ktv-select
						v-model="filter.districtId"
						rules="required"
						:loading="loading"
						:options="options.districtOptions"
						:label="$t('Filter District', 'Filter District')"
						@input="pilihandistrict"
					/>
				</b-form-group>
			</b-col>
		</b-row>
		<vcl-facebook v-show="loadingGrid" />
		<b-table
			v-show="!loadingGrid"
			id="table-id"
			ref="my-table"
			:key="items.id"
			:items="items"
			:fields="fields"
			sticky-header
			responsive
			no-border-collapse
			show-empty
			primary-key="id"
			style="height: 200px"
		>
			<template #head()="data">
				<span>{{ $t(data.label, data.label) }}</span>
			</template>
			<template #cell(id)="data">
				<b-form-checkbox @input="addItem($event, data)">&nbsp;</b-form-checkbox>
			</template>
			<template #cell(AssignCVCHead)="data">
				<b-form-group class="col-md-3 mb-2">
					<input type="checkbox" :value="data.item.id" class="form-check-input" @change="addItemAssign($event, data)" />
				</b-form-group>
			</template>
		</b-table>
		<b-row>
			<b-col align="right">
				<ktv-button v-show="!loadingGrid" :text="$t('Submit', 'Submit')" color="success" @click="onSubmit" />
			</b-col>
		</b-row>
	</div>
</template>
<script>
	const column = [
		{
			label: "",
			key: "id",
			thClass: "text-left",
		},
		{
			label: "Kiosk",
			key: "PersonName",
			thClass: "text-left",
		},
		{
			label: "Address",
			key: "Address",
			thClass: "text-left",
		},
		{
			label: "Bank Name",
			key: "BankName",
			thClass: "text-left",
		},
		{
			label: "Account Holder",
			key: "AccountHolderName",
			thClass: "text-left",
		},
		{
			label: "Account Number",
			key: "AccountNumber",
			thClass: "text-left",
		},
		{
			label: "Assign CVC Head",
			key: "AssignCVCHead",
			thClass: "text-left",
		},
	]

	import { mapActions, mapGetters, mapState } from "vuex"
	import { map } from "underscore"
	import { KtvSelect, KtvButton } from "@/components"
	export default {
		components: {
			KtvSelect,
			KtvButton,
		},
		emits: ["close-dialog"],
		data() {
			return {
				loadingGrid: false,
				loading: false,
				/* KtvTable property */
				fields: column,
				items: [],
				currentPage: 0,
				perPage: 5,
				totalItems: 0,
				isBusy: false,
				/* End KtvTable property */
				filter: {
					provinceId: null,
					districtId: null,
				},
				options: {
					provinceOptions: [],
					districtOptions: [],
				},
				itemsSave: [],
			}
		},
		computed: {
			...mapGetters({}),
			...mapState("OTHERS", ["provinceList", "districtList"]),
		},
		watch: {
			provinceList: {
				deep: true,
				handler() {
					this.getProvinceOptions()
				},
				immediate: true,
			},
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
			/* Optionally hide scrollbar when loading */
			isBusy(newVal, oldVal) {
				if (newVal !== oldVal) {
					const tableScrollBody = this.$refs["my-table"].$el
					if (newVal === true) {
						tableScrollBody.classList.add("overflow-hidden")
					} else {
						tableScrollBody.classList.remove("overflow-hidden")
					}
				}
			},
		},
		methods: {
			...mapActions({
				getDistrictList: "OTHERS/getDistrictList",
				ActionCVCTablesGridDetil: "AGRIINPUTREQUEST/ActionCVCTablesGridDetil",
				ActionAddNewSubmit: "AGRIINPUTREQUEST/ActionAddNewSubmit",
			}),
			getProvinceOptions() {
				this.options.provinceOptions = map(this.provinceList, (province) => {
					return {
						text: province.province,
						value: parseInt(province.entityProvinceID, 10),
					}
				})
			},
			getDistrictOptions() {
				this.loading = false
				this.options.districtOptions = map(this.districtList, (district) => {
					return {
						text: district.district,
						value: parseInt(district.entityDistrictID, 10),
					}
				})
			},
			setDistrict(value) {
				this.loading = true
				if (value !== null) {
					this.getDistrictList(value)
				} else {
					this.loading = false
					this.options.districtOptions = []
				}
			},
			async pilihandistrict() {
				this.loadingGrid = true
				let data = []
				const params = {
					DistrictID: this.filter.districtId,
					currentPage: 1,
					rowPerpage: 10,
				}
				const result = await this.ActionCVCTablesGridDetil(params)
				if (result.data.length > 0) {
					result.data.forEach((value, key) => {
						data.push({
							id: key + 1,
							PersonName: value.PersonName,
							Address: value.Address,
							BankName: value.BankName,
							AccountHolderName: value.AccountHolderName,
							AccountNumber: value.AccountNumber,
							EntityID: value.EntityID,
							BankID: value.BankID,
						})
					})
					this.items = data
					this.loadingGrid = false
				}
			},
			addItemAssign($event, data) {
				const index = this.itemsSave.findIndex((item) => item.id === data.item.id)
				if ($event.target.checked) {
					if (this.itemsSave[index] !== undefined) {
						this.itemsSave[index].isCvcHead = !$event.target.checked ? 0 : 1
					} else {
						this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("Can't assign CVC Head", "Can't assign CVC Head"), "error")
						$event.target.checked = false
					}
				}

				if (this.itemsSave[index] !== undefined && !$event.target.checked) {
					this.itemsSave[index].isCvcHead = 0
				}
				return false
			},
			addItem($event, data) {
				if (!$event) {
					const index = this.itemsSave.findIndex((item) => item.id === data.item.id)
					this.itemsSave.splice(index, 1)
				} else {
					this.itemsSave.push({
						districtId: this.filter.districtId,
						entityId: data.item.EntityID,
						bankAccount: data.item.AccountNumber,
						bankId: data.item.BankID,
						name: data.item.PersonName,
						address: data.item.Address,
						isCvcHead: 0,
						id: data.item.id,
					})
				}
			},
			onSubmit() {
				if (this.itemsSave.length === 0) {
					this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("No submit data found", "No submit data found"), "error")
				} else {
					this.$swal({
						type: "warning",
						title: this.$t("Confirmation", "Confirmation"),
						text: this.$t("Are you sure you want to save the data?", "Are you sure you want to save the data?"),
						showConfirmButton: true,
						showCancelButton: true,
						confirmButtonText: this.$t("Submit", "Submit"),
						cancelButtonText: this.$t("Cancel", "Cancel"),
					}).then((isConfirm) => {
						if (isConfirm.value === true) {
							this.$swal.fire({
								title: `${this.$t("Loading", "Loading")}...`,
								html: this.$t("Data will be saved", "Data will be saved"),
								allowOutsideClick: false,
								onBeforeOpen: () => {
									this.$swal.showLoading()
								},
							})
							const cvcData = {
								cvcData: this.itemsSave,
							}
							this.ActionAddNewSubmit(cvcData).then(() => {
								this.handleClose()
							})
						}
					})
				}
			},
			handleClose() {
				this.$emit("close-dialog")
			},
		},
	}
</script>
<style>
	input[type="checkbox"] {
		/* Double-sized Checkboxes */
		-ms-transform: scale(1.2); /* IE */
		-moz-transform: scale(1.2); /* FF */
		-webkit-transform: scale(1.2); /* Safari and Chrome */
		-o-transform: scale(1.2); /* Opera */
		transform: scale(1.2);
		padding: 10px;
	}
</style>
